import { handleActions } from 'redux-actions';
import { createSubscribe } from '../../containers/ScanPage/actions';
import { setLoading } from '../../utils/immer';

const subscribeReducer = handleActions(
  {
    // CREATE SUBSCRIBE
    [createSubscribe.REQUEST]: setLoading(true),
    [createSubscribe.SUCCESS]: setLoading(false),
    [createSubscribe.FAILURE]: setLoading(false),
  },
  {
    loading: false,
  },
);

export default subscribeReducer;
