import { handleActions } from 'redux-actions';
import produce from 'immer';
import {
  clearArtwork,
  getArtworkByTag,
  updateNFT,
  updateType,
} from './actions';
import { setLoading } from '../../utils/immer';
import { ArtworkDefault, nftDefault } from './records';

const artworkScanReducer = handleActions(
  {
    // GET ARTWORK BY TAG
    [getArtworkByTag.REQUEST]: setLoading(true),
    [getArtworkByTag.SUCCESS]: produce((draft, { payload: { artwork } }) => {
      draft.artwork = artwork;
      draft.loading = false;
    }),
    [getArtworkByTag.FAILURE]: setLoading(false),
    // CLEAR ARTWORK
    [clearArtwork.SUCCESS]: produce(draft => {
      draft.artwork = ArtworkDefault;
    }),
    // UPDATE NFT
    [updateNFT.SUCCESS]: produce((draft, { payload: { nft } }) => {
      draft.nft = nft;
    }),
    // UPDATE NFT
    [updateType.SUCCESS]: produce((draft, { payload: { type } }) => {
      draft.type = type;
    }),
  },
  {
    artwork: ArtworkDefault,
    nft: nftDefault,
    type: 'owner',
    loading: false,
  },
);

export default artworkScanReducer;
