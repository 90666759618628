import { createRoutine } from 'redux-saga-routines';

export const getArtwork = createRoutine('GET_ARTWORK', {
  request: id => ({
    id,
  }),
  success: artwork => ({
    artwork,
  }),
});

export const clearArtwork = createRoutine('CLEAR_ARTWORK');
