export const ArtworkDefault = {
  name: '',
  full_description: '',
  shop_link: '',
  year: null,
  edition_type: '',
  media_type: '',
  image: '',
  artists: [],
  dimensions: [],
  tag_type: {},
};

export const nftDefault = {
  value: 'UNAVAILABLE',
  color: 'gray',
};
