/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { Switch, Route } from 'react-router-dom';
import { routes, routesByTag, routesPreview } from './routes';

export default function App() {
  return (
    <>
      <Helmet titleTemplate="LuxVerity - %s" defaultTitle="LuxVerity" />
      <Switch>
        {process.env.NODE_ENV === 'preview'
          ? routesPreview.map((route) => (
              <Route
                key={route.id}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            ))
          : window.location.hostname.includes('artwork')
          ? routes.map((route) => (
              <Route
                key={route.id}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            ))
          : routesByTag.map((route) => (
              <Route
                key={route.id}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            ))}
      </Switch>
    </>
  );
}
