import { handleActions } from 'redux-actions';
import produce from 'immer';
import { clearArtwork, getArtwork } from './actions';
import { setLoading } from '../../utils/immer';
import { ArtworkDefault } from './records';

const artworkReducer = handleActions(
  {
    // GET ARTWORK
    [getArtwork.REQUEST]: setLoading(true),
    [getArtwork.SUCCESS]: produce((draft, { payload: { artwork } }) => {
      draft.artwork = artwork;
      draft.loading = false;
    }),
    [getArtwork.FAILURE]: setLoading(false),
    // CLEAR ARTWORK
    [clearArtwork.SUCCESS]: produce(draft => {
      draft.artwork = ArtworkDefault;
    }),
  },
  {
    artwork: ArtworkDefault,
    loading: false,
  },
);

export default artworkReducer;
