import { createRoutine } from 'redux-saga-routines';

export const getArtworkByTag = createRoutine('GET_ARTWORK_BY_TAG', {
  request: id => ({
    id,
  }),
  success: artwork => ({
    artwork,
  }),
});

export const getDigitalDocent = createRoutine('GET_DIGITAL_DOCENT');

export const createSubscribe = createRoutine('CREATE_SUBSCRIBE', {
  request: (email, resolve) => ({
    email,
    resolve,
  }),
});

export const updateNFT = createRoutine('UPDATE_NFT', {
  success: nft => ({
    nft,
  }),
});

export const updateType = createRoutine('UPDATE_TYPE', {
  success: type => ({
    type,
  }),
});

export const clearArtwork = createRoutine('CLEAR_ARTWORK');
