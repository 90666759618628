import HomePage from '../HomePage/Loadable';
import ArtworkPage from '../ArtworkPage/Loadable';
import AuthPage from '../AuthPage/Loadable';
import NotFoundPage from '../NotFoundPage/Loadable';
import AuthErrorPage from '../AuthErrorPage/Loadable';
import FailedReadPage from '../FailedReadPage/Loadable';
import WelcomePage from '../WelcomePage/Loadable';
import ScanPage from '../ScanPage/Loadable';
import RedirectPage from '../../components/RedirectPage/index';
import PreviewArtwork from '../PreviewArtworkPage/Loadable';

export const routes = [
  {
    id: 'homePage',
    path: '/',
    component: HomePage,
    exact: true,
  },
  {
    id: 'authPage',
    path: '/auth',
    component: AuthPage,
    exact: true,
  },
  {
    id: 'notFoundPage',
    path: '/not-found',
    component: NotFoundPage,
    exact: true,
  },
  {
    id: 'artworkPage',
    path: '/:artworkId',
    component: ArtworkPage,
    exact: true,
  },
  {
    id: 'redirect',
    path: '*',
    component: RedirectPage,
  },
];

export const routesByTag = [
  {
    id: 'failed',
    path: '/failed-auth',
    component: AuthErrorPage,
    exact: true,
  },
  {
    id: 'failedRead',
    path: '/failed-read',
    component: FailedReadPage,
    exact: true,
  },
  {
    id: 'welcome',
    path: '/welcome',
    component: WelcomePage,
    exact: true,
  },
  {
    id: 'notFound',
    path: '/not-found',
    component: NotFoundPage,
    exact: true,
  },
  window.location.search
    ? {
        id: 'scanPage',
        path: '/:nft?',
        component: ScanPage,
        exact: true,
      }
    : {
        id: 'homePage',
        path: '/',
        component: HomePage,
        exact: true,
      },
  {
    id: 'redirect',
    path: '*',
    component: NotFoundPage,
  },
];

export const routesPreview = [
  {
    id: 'previewArtwork',
    path: '*',
    component: PreviewArtwork,
    exact: true,
  },
];
